import React from "react";
import Navbar from "../components/Navbar";
import Minter from "../components/minter";
const About = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Minter />
    </div>
  );
};

export default About;
